// import style from '../../../MainContent/Options/Filter/Filter.module.sass';
import style from './SourceModal.module.sass';
import { useAppSelector } from '@/Hooks/redux';

import { Source } from './sourceCard/source';

import { useDispatch } from 'react-redux';
import { SourseSlice } from '@/Redux/Slices/SourseSlice/SourseSlice';
import { useState } from 'react';
import { FilterState } from '@/Redux/Slices/FilterSlice/FilterState';

export const SourceModal = ({ setSourcesOpen }: any) => {
	const [isSearchingPublisers, setSearchingPublisers] = useState(false);
	const [isAllSourse, SetisAllSourse] = useState(true);
	// ====================
	const dispatch = useDispatch();

	const Sourse: any = useAppSelector((state) => state.getPostProperty);
	//--------------------------

	const { SearchingPub } = FilterState.actions;

	const { SearchFilterArr } = useAppSelector((state) => state.FilterState);
	const Sorse = useAppSelector((state) => state.SourseSlice);

	// добавление всех publishers в массив

	const { DeliteAll } = SourseSlice.actions;

	return (
		<>
			<div className={style.modalSourcesBackground}></div>
			<div className={style.modalSourcesCont}>
				<div className={style.SourcesTitleCont}>
					<span className={style.modalSourcesTitle}>Источники</span>
					<svg
						onClick={() => setSourcesOpen(false)}
						className={style.xMarkAnim}
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
							fill='#686868'
						></path>
					</svg>
				</div>
				<div className={style.modalSearchDiv}>
					<svg
						width='15'
						height='15'
						viewBox='0 0 15 15'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10.7204 9.43396H10.0429L9.80274 9.2024C10.6432 8.2247 11.1492 6.9554 11.1492 5.57461C11.1492 2.49571 8.65352 0 5.57461 0C2.49571 0 0 2.49571 0 5.57461C0 8.65352 2.49571 11.1492 5.57461 11.1492C6.9554 11.1492 8.2247 10.6432 9.2024 9.80274L9.43396 10.0429V10.7204L13.7221 15L15 13.7221L10.7204 9.43396ZM5.57461 9.43396C3.43911 9.43396 1.71527 7.71012 1.71527 5.57461C1.71527 3.43911 3.43911 1.71527 5.57461 1.71527C7.71012 1.71527 9.43396 3.43911 9.43396 5.57461C9.43396 7.71012 7.71012 9.43396 5.57461 9.43396Z'
							fill='#979797'
						/>
					</svg>
					<input
						name='Поиск по источникам'
						placeholder='Поиск по источникам'
						className={style.modalSearchField}
						onChange={(e) =>
							dispatch(
								SearchingPub({
									value: e.target.value,
									AllArr: Sourse?.data.publishers,
								})
							)
						}
						onClick={() => setSearchingPublisers(true)}
					/>
				</div>
				<div className={style.modalSourcesOptionsCont}>
					<div className={style.modalSourcesOptionsCounter}>
						<span className={style.modalSourcesOptionsCounterTxt}>
							Показать
						</span>
						<div>
							<button
								className={style.modalSourcesOptionsAll}
								onClick={() => {
									SetisAllSourse(true);
								}}
							>
								все <span>{Sourse?.data?.publishers?.length}</span>
							</button>
							<button
								className={style.modalSourcesOptionsAllOfCounter}
								onClick={() => {
									SetisAllSourse(false);
								}}
							>
								выключено <span>{Sorse.BllackSourse.length}</span>
							</button>
						</div>
					</div>
					<span
						className={style.modalSourcesOptionsAllOn}
						onClick={() => {
							dispatch(DeliteAll());
						}}
					>
						ВКЛЮЧИТЬ ВСЕ
					</span>
				</div>
				<div className={style.modalSourcesContentWrapper}>
					{isSearchingPublisers
						? SearchFilterArr.map((el: any) => (
								<Source
									{...el}
									key={el.id}
								/>
						  ))
						: isAllSourse
						? Sourse?.data?.publishers?.map((el: any) => (
								<Source
									{...el}
									key={el.id}
								/>
						  ))
						: Sorse.BllackSourse.map((el: any) => {
								return (
									<Source
										{...el}
										key={el.id}
									/>
								);
						  })}
				</div>
			</div>
		</>
	);
};
