'use client';

import React, { useEffect, useState, ChangeEvent, FC } from 'react';
import style from './Filter.module.sass';
import { useAppSelector } from '@/Hooks/redux';
import { NewsViewSlice } from '@/Redux/Slices/NewsViewSlice/NewsView';
import { SoursesBtn } from '@/Features/SoursesBtn/SoursesBtn';
import { HeaderState } from '@/Redux/Slices/HeaderSlice/headerState';

import { useDispatch } from 'react-redux';
import { SourseSlice } from '@/Redux/Slices/SourseSlice/SourseSlice';
import { AboutModalWin } from '@/Features/AboutModalWin/AboutModalWin';
import { GlobalSvgSelector } from '@/lib/GlobalSVGSelector';
import { useFontSize } from '@/Hooks/useFontSize';

interface FilterProps {}

const FilterComponent: FC<FilterProps> = () => {
	const [isSearching, setSearching] = useState<boolean>(false);
	const [searchingValue, setSearchingValue] = useState<string>('');

	const dispatch = useDispatch();
	const { NewsViewChangeToCard, NewsViewChangeToLine } = NewsViewSlice.actions;
	const newsViewBollean = useAppSelector(
		state => state.NewsViewSlice.newsViewBollean
	);

	const { SetFonts } = HeaderState.actions;
	const CurrentFontSize = useAppSelector(
		state => state.HeaderState.CurrentFontSize
	);

	const Sourse = useAppSelector(state => state.getPostProperty);

	const { SetAllSourseId, deliteBlackListInWhiteList } = SourseSlice.actions;

	useEffect(() => {
		if (!Sourse.Loading) {
			dispatch(SetAllSourseId(Sourse?.data?.publishers));
		}
		dispatch(deliteBlackListInWhiteList());
	}, [Sourse.Loading, dispatch]);

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchingValue(value);
	};

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (searchingValue.trim()) {
			window.location.href = `/search/${searchingValue}`;
		}
	};
	// вызов хуйка с шрифотм
	useFontSize(CurrentFontSize);
	return (
		<section className={style.FilterOptions}>
			<section className={isSearching ? style.BtnContHide : style.BtnCont}>
				<SoursesBtn />
				<AboutModalWin />
				<div className={style.FontBtn}>
					<button
						className={CurrentFontSize == 0 ? style.minesFull : style.mines}
						// onClick={() => setState(state !== 0 ? state - 1 : state)}
						onClick={() => dispatch(SetFonts('-'))}
					>
						-
					</button>
					<div
						className={style.titleFonts}
						style={{ fontSize: `${14 + CurrentFontSize}px` }}
					>
						Шрифт
					</div>
					<button
						style={CurrentFontSize == 3 ? { opacity: '.5' } : {}}
						className={CurrentFontSize == 3 ? style.plusFull : style.plus}
						// onClick={() => setState(state !== 3 ? state + 1 : state)}
						onClick={() => dispatch(SetFonts('+'))}
					>
						+
					</button>
				</div>
				<div className={style.cardView}>
					<button
						className={newsViewBollean ? style.line : style.lineActive}
						onClick={() => dispatch(NewsViewChangeToLine())}
					>
						<GlobalSvgSelector id='cardVive-line' />
					</button>
					<button
						className={!newsViewBollean ? style.card : style.cardActive}
						onClick={() => dispatch(NewsViewChangeToCard())}
					>
						<GlobalSvgSelector id='cardVive-card' />
					</button>
				</div>
			</section>
			<section className={style.searchSection}>
				<form
					className={isSearching ? style.searchDivAnim : style.searchDiv}
					onSubmit={handleFormSubmit}
					onClick={() => setSearching(true)}
				>
					<GlobalSvgSelector id='search-zoom' />
					<input
						style={{ fontSize: `${16 + CurrentFontSize}px` }}
						name='Поиск по новостям'
						placeholder='Поиск по новостям'
						className={isSearching ? style.searchFieldAnim : style.searchField}
						onClick={() => setSearching(true)}
						onChange={handleSearchChange}
					/>

					{isSearching && (
						<button
							type='submit'
							style={{ fontSize: `${14 + CurrentFontSize}px` }}
							className={style.searchBtn}
						>
							НАЙТИ
						</button>
					)}
				</form>
				{isSearching && (
					<svg
						onClick={() => setSearching(false)}
						className={isSearching ? style.xMarkAnim : style.xMark}
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
							fill='#686868'
						></path>
					</svg>
				)}
			</section>
		</section>
	);
};

export const Filter = React.memo(FilterComponent);
