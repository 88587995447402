import style from './options.module.sass';
import { Tags } from '../../Features/Tags/Tags';
import { Filter } from '../../components/MainContent/Filter/Filter';

export default function Options() {
	return (
		<section className={style.MainOptionCont}>
			<section className={style.Options}>
				<Filter />
				<Tags />
			</section>
		</section>
	);
}
