'use client';

import { useEffect, useState } from 'react';
import style from './source.module.sass';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { SourseSlice } from '@/Redux/Slices/SourseSlice/SourseSlice';
import { useAppSelector } from '@/Hooks/redux';

export const Source = (El: any) => {
	// redux

	const dispatch = useDispatch();
	const {
		SetAllBllackSourse,
		DeleteAllBllackSourse,
		AddOffToggle,
		DeleteOffToggle,
		deliteBlackListInWhiteList,
		addWhiteList
	} = SourseSlice.actions;

	const Sourse = useAppSelector(state => state.SourseSlice);

	const [isSwitcherOn, setSwitcherOn] = useState(
		!Sourse.ArrOffToggle?.includes(El.id)
	);
	const SwitcherFunc = () => {
		if (isSwitcherOn === true) {
			setSwitcherOn(false);
			dispatch(SetAllBllackSourse(El));
			dispatch(AddOffToggle(El));
			dispatch(deliteBlackListInWhiteList());
		} else {
			setSwitcherOn(true);
			dispatch(DeleteAllBllackSourse(El));
			dispatch(DeleteOffToggle(El));
			dispatch(addWhiteList(El));
		}
	};
	useEffect(() => {
		if (Sourse.ArrOffToggle.length === 0 && Sourse.OffAllToggle === true) {
			setSwitcherOn(true);
		}
	}, [Sourse.ArrOffToggle]);
	return (
		<Link href={`/publisher/${El.id}`} style={{ textDecoration: 'none' }}>
			<section className={style.Wrapper} key={El.id}>
				<div className={style.SourceData}>
					<Image width={16} height={16} src={El.logo_url} alt='' />

					<div>
						<span className={style.SourceAgency}>{El.name}</span>
						<Link className={style.SourceUrl} href={`/publisher/${El.id}`}>
							{El.domain}
						</Link>
					</div>
				</div>

				<div
					className={
						isSwitcherOn ? style.SourceToggleOn : style.SourceToggleOff
					}
					onClick={e => {
						e.preventDefault();
						SwitcherFunc();
					}}
				>
					<div className={style.SourceToggleSwitcher}></div>
				</div>
			</section>
		</Link>
	);
};
