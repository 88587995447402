'use client';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { log } from 'winston';
import { useDispatch } from 'react-redux';

export const Message = ({ funct, rest }: any) => {
	const [refresh, setRefresh] = useState<boolean>(false);
	const dispatch = useDispatch();
	useEffect(() => {
		const handleMessage = (event: any) => {
			const JsonValidate = () => {
				try {
					JSON.parse(event?.data)[0];
				} catch (e) {
					return false;
				}
				return true;
			};
			if (JsonValidate() && JSON.parse(event?.data)[0] === 'updateArticles') {
				// const url = new URL(window.location.href);
				// url.searchParams.set('reloaded', 'true');
				// window.location.href = url.toString();
				setRefresh(true);

				dispatch(rest());
				dispatch(funct);
			}
		};

		document.addEventListener('message', handleMessage);

		return () => {
			document.removeEventListener('message', handleMessage);
		};
	}, [funct]);

	useEffect(() => {
		if (refresh) {
			if (window.ReactNativeWebView !== undefined) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify(['setTeasersLoadingState', false])
				);
			}

			setRefresh(false);
		}
	}, [refresh]);
	return null;
};
