import { useAppSelector } from '@/Hooks/redux';
import style from './SoursesBtn.module.sass';
import { SourceModal } from '@/Features/sourcesModalWindow/SourceModal';
import { useState } from 'react';
export const SoursesBtn = () => {
	const [isSourcesOpen, setSourcesOpen] = useState(false);

	const { CurrentFontSize } = useAppSelector((state) => state.HeaderState);
	return (
		<>
			<button
				className={style.inputBtn}
				onClick={() => setSourcesOpen(true)}
				style={{ fontSize: `${14 + CurrentFontSize}px` }}
			>
				<div className={style.inputBtnDiv}>
					<svg
						width='18'
						height='18'
						viewBox='0 0 18 12'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							opacity='0.4'
							d='M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z'
							fill='black'
						></path>
					</svg>
					<span>Источники</span>
				</div>
			</button>
			{/* модал окно источники */}
			{isSourcesOpen && <SourceModal setSourcesOpen={setSourcesOpen} />}
		</>
	);
};
